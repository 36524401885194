var app = {
	windowLoaded: function() {
		var _main=this;	
		$('#preloader').fadeOut(450, function() {
			$(this).remove();
		});
	},
	windowResized: function() {
		var _main=this;
		_main.getViewport();
		_main.calculateSlider();
		
	},
	windowScrolled: function() {
		var _main=this;
	},	
	cookies: {		
		createCookie: function(name, value, days) {		    
		    var _main=app;
		    var date = new Date();
		    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		    var expires = "; expires=" + date.toGMTString();
		    document.cookie = name + "=" + value + expires + "; path=/";
		    (_main.debug)?console.log('creating cookie: ',name):'';
		},
		readCookie: function(name) {
		    var _main=app;
		    var nameEQ = name + "=";
		    var ca = document.cookie.split(';');
		    for (var i = 0; i < ca.length; i++) {
		    	var c = ca[i];
		    	while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		    	if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		    }
		    return null;
		},
		checkCookies: function() {
		    var _main=app;
		    if (_main.cookies.readCookie(_main.cookieName) != 1) {
		      (_main.debug)?console.log('cookie %s is not created yet',_main.cookieName):'';
		      _main.cookies.notification('show');
		    } else {
		    	(_main.debug)?console.log('cookie %s already exists',_main.cookieName):'';
		    }
		    $('.closeNotification').on('click',function () {
		      _main.cookies.createCookie(_main.cookieName, 1, 365);
		      _main.cookies.notification('hide');
				});
				$('.closeNotificationForNow').on('click',function () {		      
		      $('.cookiesNotification').remove();
		    });
		},
		notification: function(state) {
			var _main=app;
			switch (state) {
				case 'show':
					$('main').append('<div class="cookiesNotification"><div class="wrapper"><p>Serwis wykorzystuje pliki cookies w celu zapewnienia pełnej funkcjonalności i poprawy jakości działania. Kontynuując przeglądanie witryny bez zmiany ustawień przeglądarki dotyczących plików cookies, wyrażasz zgodę na ich wykorzystanie. Jeśli potrzebujesz dodatkowych informacji odwiedź <a href="https://www.sandoz.pl/polityka-prywatnosci" target="_blank">tę stronę.</a></p><div class="btn equal closeNotification">Akceptuję</div></div></div>');
				break;
				case 'hide':					
					$('.cookiesNotification').fadeOut(300, function () {
				        $(this).remove();
				      });
				break;
			}
		}
	},	
	calculateSlider: function() {
		var _main=this;
		if (_main.viewport == 'desktop') {
			if ($('.items-slider').hasClass('slick-initialized')) {
				$('.items-slider .slick-slide').height($('.items-slider').height());			
				_main.debug?console.log('slider sections height set'):'';
			}
		}
	},
	handleSliders: function() {
		var _main=app;
		if (_main.viewport == 'desktop') {
			$('.items-slider').slick({
				dots: true,
				infinite: true,
				arrows: true,
				// adaptiveHeight: false			
				adaptiveHeight: true			
			});
			setTimeout(function(){
				_main.calculateSlider();
			}, 300);			
		}
		if (_main.viewport != 'desktop') {
			$('.steps-slider').slick({
				dots: false,
				infinite: true,
				arrows: true,
				adaptiveHeight: true		
			});
		}		
		if ($('.slider-articles').length) {
			if (_main.viewport == 'mobile' || _main.viewport == 'tablet') {
				$('.slider-articles.v2').slick({
					dots: true,
					infinite: true,
					arrows: false,
					adaptiveHeight: true,
					slidesToShow: 2,
					responsive: [
						{
							breakpoint: 640,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								infinite: true,
								dots: true
							}
						}
					]
				});
			}		
		}		
	},
	handleParticles: function() {
		var _main=this;
		if (_main.viewport == 'desktop') {			
			if ($('#particles-js').length) {
				_main.debug?console.log('initializing particles'):'';
				particlesJS("particles-js", {"particles":{"number":{"value":80,"density":{"enable":true,"value_area":800}},"color":{"value":"#ffffff"},"shape":{"type":"circle","stroke":{"width":0,"color":"#000000"},"polygon":{"nb_sides":5},"image":{"src":"img/github.svg","width":100,"height":100}},"opacity":{"value":0.5,"random":false,"anim":{"enable":false,"speed":1,"opacity_min":0.1,"sync":false}},"size":{"value":1.2,"random":true,"anim":{"enable":false,"speed":40,"size_min":0.1,"sync":false}},"line_linked":{"enable":false,"distance":150,"color":"#ffffff","opacity":0.4,"width":1},"move":{"enable":true,"speed":6,"direction":"none","random":false,"straight":false,"out_mode":"out","bounce":false,"attract":{"enable":false,"rotateX":600,"rotateY":1200}}},"interactivity":{"detect_on":"canvas","events":{"onhover":{"enable":false,"mode":"repulse"},"onclick":{"enable":false,"mode":"push"},"resize":true},"modes":{"grab":{"distance":400,"line_linked":{"opacity":1}},"bubble":{"distance":400,"size":40,"duration":2,"opacity":8,"speed":3},"repulse":{"distance":200,"duration":0.4},"push":{"particles_nb":4},"remove":{"particles_nb":2}}},"retina_detect":true});var count_particles, stats, update;
			}
		} else {
			_main.debug?console.log('particles disabled'):'';
		}
	},
	events: function() {
		var _main=this;

		$('body').on('click', '.trigger-legal, .close-legal', function() {
			$('.legal-box').toggleClass('show');
		});

		if (_main.viewport == 'mobile' || _main.viewport == 'tablet') {
			$('body.has-scrollable-menu .sub-menu a').on('click', function(e) {				
				e.preventDefault();
				target = $(this).attr('href').split("#").pop();
				_main.debug?console.log('go to hash: ',target):'';
				$('.hamburger').click();
				var offset_top = (target == 'porada1')?75:0;
				$("html, body").animate({            	
					// scrollTop: $('[data-content="'+target+'"]').offset().top - offset_top
					scrollTop: $('[data-content="'+target+'"]').offset().top
				}, 1100); 				
			});
		}

		$('.hamburger').on('click', function() {
			$(this).toggleClass('is-active');
			$('nav').toggleClass('show');
		});		


		$('div[itemprop="mainEntity"] h2').on('click', function() {
			$(this).toggleClass('active').next('div[itemprop="acceptedAnswer"]').slideToggle();
		});
	},	
	calculateRatio: function(scrWidth,scrHeight) {
		var _main=this;
		ratio = scrWidth / scrHeight;	
		_main.debug?console.log('screen ratio: ',ratio):'';
		return ratio;
	},
	getViewport: function() {
		var _main=this;
		viewportWidth=$(window).width();
		viewportHeight=$(window).height();
		beforeState = _main.viewport;
		_main.debug?console.log('current viewport: ',beforeState):'';
		if (viewportWidth<=_main.breakpointMobile) {			
			_main.viewport='mobile';
			$('body').addClass('start');
		} else if (viewportWidth>_main.breakpointMobile && viewportWidth<=_main.breakpointTablet) {
			_main.viewport='tablet';
			$('body').addClass('start');
		} else {
			_main.viewport='desktop';
		}
		(_main.debug)?console.log('viewport set: ',_main.viewport):'';		

		/* refresh page if the viewport changes to different device type */
		if (beforeState != null) {
			beforeState == _main.viewport?console.log('the same viewport type after resize'):location.reload();
		} else {
			_main.debug?console.log('no viewport change'):'';
		}
		
		/* make some magic when screen is more square */
		_main.screenRatio = _main.calculateRatio(viewportWidth,viewportHeight);
		if (_main.screenRatio >= _main.ratioBreakpointDown) {
			_main.allowSections = true;
			// _main.handleSections();		
			if (_main.viewport == 'desktop') {
				_main.debug?console.log('enable content'):'';
				$('body').removeClass('placeholder');
			}
		} else {
			_main.allowSections = false;			
			if (_main.viewport == 'desktop') {
				_main.debug?console.log('disable content'):'';
				$('body').addClass('placeholder');
			}
		}		
	},
	init: function() {
		_main=this;
		_main.getViewport();
		
		_main.events();	
		_main.handleSliders();
		_main.handleParticles();
		
		// _main.cookies.checkCookies();
	},

	debug: true,	
	debugOutput: false,
	devTest: false,
	viewport: null,
	cookieName: 'nasometin_landing_page',
	ratioBreakpointDown: 1.58,
	ratioBreakpointUp: 1.58,
	scrollEngine: false,
	screenRatio: null,
	allowSections: false,

	breakpointMobile: 640,
	breakpointTablet: 1280
}

$(document).ready(function() {
	app.init();	
});

$(window).on('load', function() {
	app.windowLoaded();
});

$(window).resize(function() {
	app.windowResized();
});

$(document).scroll(function() {
	app.windowScrolled();
});
